<template>
  <a-row>
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row
        class="flex-no__wrap ml-auto wanted-search-input-wrapper"
        type="flex"
      >
        <!-- <searchInput
          v-model="inputs.search.text"
          :placeholder="inputs.search.placeholder[$i18n.locale]"
          :searching="inputs.search.spinner"
          @handler="onSearch"
        /> -->
        <a-button
          type="primary"
          @click="
            $router.push({
              name: 'region-create'
            })
          "
        >
          <a-icon type="plus" />{{ $t("Add") }}
        </a-button>
      </a-row>
    </a-row>
    <spinner v-if="loading" />
    <a-table
      v-else-if="regions.length"
      :key="$route.fullPath"
      :columns="columns"
      :data-source="regions"
      :loading="loading"
      :pagination="true"
      @change="handleTableChange"
    >
      <template slot="name" slot-scope="item">
        {{ item.title || "-" }}
      </template>
      <template slot="operation" slot-scope="item">
        <a-icon
          class="action-btns"
          style="margin: 0 10px"
          type="edit"
          @click="
            $router.push({
              name: 'region-detail',
              params: { id: item.id }
            })
          "
        />
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeItem(item.id)"
        >
          <a-icon class="action-btns" style="margin: 0 10px" type="delete" />
        </a-popconfirm>
      </template>
    </a-table>
    <p v-else>{{ $t("CountiesEmpty") }}</p>

    <!-- <a-row class="mt-1">
      <a-pagination
        :current="page"
        :total="+tableProps.count || 0"
        @change="toPage"
      />
    </a-row> -->
  </a-row>
</template>
<script>
export default {
  data() {
    return {
      columns: [
        {
          title: this.$t("TagsColTitle"),
          key: "title",
          class: "wanted-fio-td",
          scopedSlots: { customRender: "name" },
          width: "90%"
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          scopedSlots: { customRender: "operation" }
        }
      ],
      regions: [],
      loading: false
    }
  },
  created() {
    this.getRegionList()
  },
  methods: {
    async getRegionList() {
      this.loading = true
      this.regions = await this.$store.dispatch("region/fetchRegionList")
      this.loading = false
    },
    async removeItem(id) {
      await this.$api.delete(`/admin/common/region/${id}/delete/`)
      await this.getRegionList()
    },
    handleTableChange() {
      console.log("edit")
    }
  }
}
</script>
<style></style>
